import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default () => (
  <Layout>
    <SEO
      title="Amsterdam - Europe | Kiwi & Cacahuate"
      description="5 week trip to Europe"
      url="https://kiwicacahuate.com/amsterdam/"
      image="https://kiwicacahuate.com/amsterdam/amsterdam.jpg"
      imageWidth="4000"
      imageHeight="2668"
    />

    <div className="">
      <div className="story">
        <div className="photoset-section">
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/amsterdam/amsterdam.webp, /amsterdam/amsterdam.webp 2x" />
                <source srcSet="/amsterdam/amsterdam.jpg, /amsterdam/amsterdam.jpg 2x" />
                <img
                  src="/amsterdam/amsterdam.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/amsterdam/thumbnail/001.webp, /amsterdam/thumbnail/001-2x.webp 2x" />
                <source srcSet="/amsterdam/thumbnail/001.jpg, /amsterdam/thumbnail/001-2x.jpg 2x" />
                <img
                  src="/amsterdam/thumbnail/001.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/amsterdam/thumbnail/002.webp, /amsterdam/thumbnail/002-2x.webp 2x" />
                <source srcSet="/amsterdam/thumbnail/002.jpg, /amsterdam/thumbnail/002-2x.jpg 2x" />
                <img
                  src="/amsterdam/thumbnail/002.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/amsterdam/thumbnail/003.webp, /amsterdam/thumbnail/003-2x.webp 2x" />
                <source srcSet="/amsterdam/thumbnail/003.jpg, /amsterdam/thumbnail/003-2x.jpg 2x" />
                <img
                  src="/amsterdam/thumbnail/003.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/amsterdam/thumbnail/004.webp, /amsterdam/thumbnail/004-2x.webp 2x" />
                <source srcSet="/amsterdam/thumbnail/004.jpg, /amsterdam/thumbnail/004-2x.jpg 2x" />
                <img
                  src="/amsterdam/thumbnail/004.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/amsterdam/thumbnail/005.webp, /amsterdam/thumbnail/005-2x.webp 2x" />
                <source srcSet="/amsterdam/thumbnail/005.jpg, /amsterdam/thumbnail/005-2x.jpg 2x" />
                <img
                  src="/amsterdam/thumbnail/005.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/amsterdam/thumbnail/006.webp, /amsterdam/thumbnail/006-2x.webp 2x" />
                <source srcSet="/amsterdam/thumbnail/006.jpg, /amsterdam/thumbnail/006-2x.jpg 2x" />
                <img
                  src="/amsterdam/thumbnail/006.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>

          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/amsterdam/thumbnail/008.webp, /amsterdam/thumbnail/008-2x.webp 2x" />
                <source srcSet="/amsterdam/thumbnail/008.jpg, /amsterdam/thumbnail/008-2x.jpg 2x" />
                <img
                  src="/amsterdam/thumbnail/008.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/amsterdam/thumbnail/009.webp, /amsterdam/thumbnail/009-2x.webp 2x" />
                <source srcSet="/amsterdam/thumbnail/009.jpg, /amsterdam/thumbnail/009-2x.jpg 2x" />
                <img
                  src="/amsterdam/thumbnail/009.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/amsterdam/thumbnail/010.webp, /amsterdam/thumbnail/010-2x.webp 2x" />
                <source srcSet="/amsterdam/thumbnail/010.jpg, /amsterdam/thumbnail/010-2x.jpg 2x" />
                <img
                  src="/amsterdam/thumbnail/010.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/amsterdam/thumbnail/011.webp, /amsterdam/thumbnail/011-2x.webp 2x" />
                <source srcSet="/amsterdam/thumbnail/011.jpg, /amsterdam/thumbnail/011-2x.jpg 2x" />
                <img
                  src="/amsterdam/thumbnail/011.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/amsterdam/thumbnail/012.webp, /amsterdam/thumbnail/012-2x.webp 2x" />
                <source srcSet="/amsterdam/thumbnail/012.jpg, /amsterdam/thumbnail/012-2x.jpg 2x" />
                <img
                  src="/amsterdam/thumbnail/012.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/amsterdam/thumbnail/013.webp, /amsterdam/thumbnail/013-2x.webp 2x" />
                <source srcSet="/amsterdam/thumbnail/013.jpg, /amsterdam/thumbnail/013-2x.jpg 2x" />
                <img
                  src="/amsterdam/thumbnail/013.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/amsterdam/thumbnail/014.webp, /amsterdam/thumbnail/014-2x.webp 2x" />
                <source srcSet="/amsterdam/thumbnail/014.jpg, /amsterdam/thumbnail/014-2x.jpg 2x" />
                <img
                  src="/amsterdam/thumbnail/014.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/amsterdam/thumbnail/015.webp, /amsterdam/thumbnail/015-2x.webp 2x" />
                <source srcSet="/amsterdam/thumbnail/015.jpg, /amsterdam/thumbnail/015-2x.jpg 2x" />
                <img
                  src="/amsterdam/thumbnail/015.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)
